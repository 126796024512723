<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-else
      border
      rounded
      :hover="hasPermissionForEditingProduct"
      :data="data"
      :columns="columns"
      :show-index="currentIndex"
      :cell-class="handleCellClass"
      @row-click="onRowClicked"
    >
      <template v-if="hasPermissionForEditingProduct" #cell-kebab="{ rowData, rowIndex }">
        <el-dropdown
          class="d-flex justify-content-end ms-1"
          trigger="click"
          :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
          @command="(action) => handleAction(action, rowData)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button type="icon" class="p-1 more-btn" @click.stop>
            <KebabIcon />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="CATALOG_ACTIONS.DELETE">
              <div class="delete-action">
                <TrashCanIcon />
                <p>{{ $t('catalog.table.deleteCatalog') }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <el-pagination
      class="my-2 float-right pb-4"
      layout="prev, pager, next, jumper"
      small
      hide-on-single-page
      background
      :page-size="limit"
      :total="totalCount"
      :page-count="Math.ceil(totalCount / limit)"
      @current-change="onPageChanged"
    />
  </div>
</template>
<script>
import { computed, getCurrentInstance, ref } from 'vue';

import { TableLoadingSkeleton, Table, Button } from '@/modules/core';
import { KebabIcon, TrashCanIcon } from '@/assets/icons';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

import { CATALOG_TABLE_HEADERS, getCatalogColumns } from './catalogColumns';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';
import { useUserPermissions } from '@/modules/auth';

const CATALOG_ACTIONS = {
  DELETE: 'delete',
};
export default {
  name: 'CatalogTable',
  components: {
    Table,
    Button,
    KebabIcon,
    TrashCanIcon,
    TableLoadingSkeleton,
  },
  props: {
    catalogs: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    deleteLoading: { type: Boolean, default: false },
    isDeleteModalOpen: { type: Boolean, default: false },
    page: { type: Number, default: 0 },
    limit: { type: Number, default: 0 },
    totalCount: { type: Number, default: 0 },
    isSupplierView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-page-change', 'on-catalog-edit', 'on-catalog-to-delete-click'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForEditingProduct = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_PRODUCT);

    const activeRowIndex = ref(-1);
    const selectedSuppliers = ref([]);

    const currentIndex = computed(() => props.page * props.limit + 1);

    const data = computed(() =>
      props.catalogs.map((catalog) => ({
        ...catalog,
        [CATALOG_TABLE_HEADERS.SUPPLIER]: catalog.business?.name,
        [CATALOG_TABLE_HEADERS.PRODUCT_COUNT]: catalog.productIds?.length ?? 0,
        [CATALOG_TABLE_HEADERS.LINKED_BUSINESSES]: catalog.isPublic
          ? MISSING_DATA_TEXT
          : catalog.exposedToBusinesses?.length,
        [CATALOG_TABLE_HEADERS.ACCESS]: catalog.isPublic
          ? root.$t('catalog.table.openToEveryone')
          : root.$t('catalog.table.limited'),
      }))
    );

    const columns = getCatalogColumns(props.isSupplierView);

    const handleCellClass = (rowIndex) => {
      if (rowIndex === activeRowIndex.value) {
        return 'active-row';
      }
    };

    const handleAction = (action, catalog) => {
      if (action === CATALOG_ACTIONS.DELETE) {
        emit('on-catalog-to-delete-click', catalog);
      }
    };

    const actionsVisibleChange = (rowIndex, isVisible) => {
      activeRowIndex.value = isVisible ? rowIndex : -1;
    };

    const onPageChanged = (newPage) => {
      emit('on-page-change', newPage - 1);
    };

    const onRowClicked = (rowIndex) => {
      if (!hasPermissionForEditingProduct.value) {
        return;
      }

      emit('on-catalog-edit', data.value[rowIndex]);
    };

    return {
      CATALOG_ACTIONS,
      CATALOG_TABLE_HEADERS,
      data,
      columns,
      currentIndex,
      selectedSuppliers,
      handleAction,
      onRowClicked,
      onPageChanged,
      handleCellClass,
      actionsVisibleChange,
      hasPermissionForEditingProduct,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .active-row {
    background-color: #f8fafb;
  }
}

tr {
  .more-btn {
    visibility: hidden;
  }
  .active-row {
    .more-btn {
      visibility: visible;
      background-color: #ecf0f3;
    }
  }
  &:hover {
    .more-btn {
      visibility: visible;
    }
  }
}

.delete-action {
  color: $checkbox-error;
  display: flex;
  height: 2rem;
  align-items: center;
  gap: 0.5rem;
}
</style>
