<template>
  <div class="d-flex flex-column">
    <div class="w-100 d-flex justify-content-between" :style="{ marginBottom: isSupplierView ? '1rem' : '' }">
      <p class="catalog-title">{{ $t('catalog.table.title', { count: totalCount }) }}</p>
      <Button v-if="hasPermissionForEditingProduct" type="primary" @click="showAddCatalogModal = true">{{
        $t('catalog.table.addCatalog')
      }}</Button>
    </div>
    <div class="d-flex flex-column gap-4">
      <div v-if="!isSupplierView" class="d-flex w-100 justify-content-between">
        <SearchableDropdown
          :loading="!isCatalogsSupplierDone"
          :label="$t('catalog.table.headers.supplier')"
          :selected-item="selectedSupplier"
          :search-placeholder="$t('catalog.table.searchSupplier')"
          :options="suppliers"
          :custom-width="'300px'"
          @change="onSelectSupplier"
        />
      </div>
      <CatalogTable
        :page="page"
        :loading="loading"
        :limit="tablePageLimit"
        :catalogs="catalogs"
        :total-count="totalCount"
        :is-supplier-view="isSupplierView"
        @on-catalog-edit="onCatalogEdit"
        @on-page-change="onPageChanged"
        @on-catalog-to-delete-click="onCatalogToDeleteClick"
      />
      <AddCatalogModal
        v-if="showAddCatalogModal"
        :visible="showAddCatalogModal"
        :is-single-product="false"
        :is-supplier-view="isSupplierView"
        :supplier="currentTenant"
        @on-close="showAddCatalogModal = false"
        @on-added-catalog="onAddedCatalog"
      />
      <EditCatalogModal
        v-if="showEditCatalogModal"
        :visible="showEditCatalogModal"
        :catalog="catalogToEdit"
        :is-supplier-view="isSupplierView"
        @on-close="showEditCatalogModal = false"
        @on-updated-catalog="onUpdatedCatalog"
      />
      <ActionsModal
        v-if="isDeleteModalOpen"
        :toggle-dialog="isDeleteModalOpen"
        :title="$t('catalog.table.deleteCatalog')"
        :content="$t('catalog.table.deleteCatalogConfirmation', { name: catalogToDelete.name })"
        :confirm-button-text="$t('catalog.table.delete')"
        :confirm-button-loading="deleteLoading"
        :cancel-button-text="$t('cancel')"
        :dialog-type="DIALOG_TYPES.DANGER"
        @on-cancel="onFinishDeleteCatalog"
        @on-close="onFinishDeleteCatalog"
        @on-confirm="onDeleteCatalog"
      />
    </div>
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { ActionsModal, Button } from '@/modules/core';
import { DIALOG_TYPES } from '@/modules/core/components/constants';
import { SearchableDropdown } from '@/modules/document/components/table-filters';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';

import CatalogTable from './CatalogTable';
import AddCatalogModal from './AddCatalogModal.vue';
import EditCatalogModal from './EditCatalogModal.vue';
import { useCatalogTable } from '../compositions/useCatalogTable';
import { useCatalogSuppliers } from '../compositions/useCatalogSuppliers';
import { useCatalogDelete } from '../compositions/useDeleteCatalog';
import { useTenancy, useUserPermissions } from '@/modules/auth';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';

const PAGE_LIMIT = 25;

export default {
  name: 'Catalogs',
  components: { Button, ActionsModal, CatalogTable, AddCatalogModal, EditCatalogModal, SearchableDropdown },
  props: {
    isSupplierView: {
      type: Boolean,
      default: false,
    },
    tablePageLimit: {
      type: Number,
      default: PAGE_LIMIT,
    },
  },
  emits: [''],
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForEditingProduct = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_PRODUCT);

    const page = ref(0);
    const selectedSupplier = ref();
    const catalogToDelete = ref();
    const showAddCatalogModal = ref(false);
    const showEditCatalogModal = ref(false);
    const isDeleteModalOpen = ref(false);
    const catalogToEdit = ref();

    const { catalogs, loading, totalCount, refetch } = useCatalogTable(
      computed(() => ({
        ...(props.isSupplierView
          ? { businessId: currentTenant.value.id }
          : selectedSupplier.value !== null
          ? { businessId: selectedSupplier.value?.id }
          : {}),
        first: props.tablePageLimit,
        after: page.value * props.tablePageLimit,
      }))
    );

    const { allResults: catalogSuppliers, isDone: isCatalogsSupplierDone } = useFetchAll({
      limit: 500,
      dataSelector: 'catalogs',
      hook: useCatalogSuppliers,
    });

    const suppliers = computed(() => {
      const supplierIdSet = new Set();
      const suppliersToReturn = [];

      if (isCatalogsSupplierDone.value) {
        for (const { business } of catalogSuppliers.value) {
          if (business && business.id && !supplierIdSet.has(business.id)) {
            supplierIdSet.add(business.id);
            suppliersToReturn.push(business);
          }
        }
      }

      return suppliersToReturn;
    });

    const { deleteCatalog, loading: deleteLoading, onDone } = useCatalogDelete();

    onDone(() => {
      root.$message.success(root.$t('catalog.table.deleteSucceeded'));
      onFinishDeleteCatalog();
      refetch();
    });

    const onPageChanged = (newPage) => {
      page.value = newPage;
    };

    const onSelectSupplier = (choice) => {
      if (choice) selectedSupplier.value = { id: choice.id, name: choice.name };
      else selectedSupplier.value = null;
    };

    const onCatalogToDeleteClick = (catalog) => {
      catalogToDelete.value = catalog;
      isDeleteModalOpen.value = true;
    };

    const onDeleteCatalog = () => {
      deleteCatalog({ id: catalogToDelete.value.id });
    };

    const onFinishDeleteCatalog = () => {
      catalogToDelete.value = null;
      isDeleteModalOpen.value = false;
    };

    const onAddedCatalog = () => {
      showAddCatalogModal.value = false;
      refetch();
    };

    const onCatalogEdit = (catalog) => {
      showEditCatalogModal.value = true;
      catalogToEdit.value = catalog;
    };

    const onUpdatedCatalog = () => {
      showEditCatalogModal.value = false;
      refetch();
    };

    return {
      PAGE_LIMIT,
      DIALOG_TYPES,
      page,
      loading,
      catalogs,
      suppliers,
      totalCount,
      catalogToEdit,
      deleteLoading,
      catalogToDelete,
      selectedSupplier,
      isDeleteModalOpen,
      showAddCatalogModal,
      showEditCatalogModal,
      isCatalogsSupplierDone,
      onSelectSupplier,
      onPageChanged,
      onCatalogEdit,
      onAddedCatalog,
      onDeleteCatalog,
      onUpdatedCatalog,
      onFinishDeleteCatalog,
      onCatalogToDeleteClick,
      currentTenant,
      hasPermissionForEditingProduct,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.catalog-title {
  font-weight: 700;
  font-size: 1rem;
  color: $typography-primary;
}

.catalogs-searchbar {
  width: 16rem !important;
}
</style>
